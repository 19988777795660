// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dezynfekcja-js": () => import("./../../../src/pages/dezynfekcja.js" /* webpackChunkName: "component---src-pages-dezynfekcja-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-mat-stom-js": () => import("./../../../src/pages/mat_stom.js" /* webpackChunkName: "component---src-pages-mat-stom-js" */),
  "component---src-pages-neo-js": () => import("./../../../src/pages/neo.js" /* webpackChunkName: "component---src-pages-neo-js" */),
  "component---src-pages-promocje-js": () => import("./../../../src/pages/promocje.js" /* webpackChunkName: "component---src-pages-promocje-js" */),
  "component---src-pages-sdr-js": () => import("./../../../src/pages/sdr.js" /* webpackChunkName: "component---src-pages-sdr-js" */)
}

